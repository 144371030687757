@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gradient {
  --size: 250px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(90deg, #ffb800, #ff7a00, #5200ff);
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 70%;
  bottom: 23%;
  margin-top: -121rem;
}

@media (min-width: 720px) {
  .gradient {
    --size: 100vw;
  }
}

body {
  background-color: #080808;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* This is just to transition when you change the viewport size. */
* {
  transition: all 0.25s ease-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gradient {
  --size: 250px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(hsl(158, 82, 57, 85%), hsl(252, 82, 57));
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 70%;
  bottom: 23%;
  margin-top: -121rem;
}

@media (min-width: 720px) {
  .gradient {
    --size: 100vw;
  }
}

body {
  background-color: #080808;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* This is just to transition when you change the viewport size. */
* {
  transition: all 0.25s ease-out;
}
